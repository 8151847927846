import { Link, graphql } from "gatsby"
import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import SEO from "../../components/SEO"
import seoContents from "../../constants/seo-contents"
import Banner from '../../components/our-work/first-level/Banner'
import OurWorkComponent from '../../components/OurWorkComponent'

const Gallery = ({ data }) => {
  return (
    <MainLayout>
      <SEO title={`${seoContents.gallery.title}`} description={`${seoContents.gallery.description}`} />
      <Banner sources={data.strapiGalleryHomepage.HeroImage} />
      <section className="gallery">
        <h2>{data.strapiGalleryHomepage.Header}</h2>
        <div className="contents">
          <div>
            <article>{data.strapiGalleryHomepage.Content}</article>
            <Link to={data.strapiGalleryHomepage.BtnTarget} className="footer-contribute-button">
              <span>{data.strapiGalleryHomepage.BtnText}</span>
            </Link>
            <h3 className="slogan">{data.strapiGalleryHomepage.Slogan}</h3>
          </div>
          <ul>
            {data.allStrapiGalleries && data.allStrapiGalleries.edges && data.allStrapiGalleries.edges.map(gallery => (
              <li>
                <Link to={gallery.node.slug}>
                  <img src={`${gallery.node.thumbnail.localFile.publicURL}`} width={203} height={203} alt={gallery.node.thumbnail.id} />
                </Link>
                <p>{gallery.node.name}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <OurWorkComponent />
    </MainLayout>
  )
}

export default Gallery


export const pageQuery = graphql`  
  query {
    strapiGalleryHomepage {
      Header
      Content
      BtnText
      BtnTarget
      Slogan
      HeroImage {
        desktopImage {
          localFile {
            publicURL
          }
        }
        mobileImage {
          localFile {
            publicURL
          }
        }
        location
      }
    }
    allStrapiGalleries {
        edges {
          node {
            id
            name
            slug
            thumbnail {
              localFile {
                publicURL
              }
            }
            heroImage {
              id
              desktopImage {
                localFile {
                  publicURL
                }
              }
              mobileImage {
                localFile {
                  publicURL
                }
              }
              location
            }
          }
        }
      }
  }
`