const seoContents = {
    errorPage: {
        title: '404',
        description: '404 - Not found'
    },
    contribute: {
        title: 'Contribute to support Peace Train global programmes',
        description: 'Jump Upon The Peace Train…support our programmes to help feed the hungry and spread peace around the world.'
    },
    incomeAndSpending: {
        title: 'Income and spending - Peace Train financial information for this year',
        description: 'All our income, expenditure and allocations summarised for the current financial year to see how your donations are working.'
    },
    homePage: {
        title: 'Home Page - Everyone Jump Upon The Peace Train',
        description: 'We are at a moment in the human story where we must come together as a global family and embrace peace and sustainability.'
    },
    stayInTouch: {
        title: 'Stay in Touch - Contact us here at Peace Train',
        description: 'Want to be kept up to date on the work of Peace Train? Sign up to keep informed of what we are doing here.'
    },
    aboutUs: {
        title: 'About us - Who we are and what we do at Peace Train',
        description: 'Dreaming About A World As One…our mission is to feed the hungry and spread peace. Read all about who we are and what we do.'
    },
    privacyPolicy: {
        title: 'Privacy Policy',
        description: 'Privacy Policy'
    },
    gallery: {
        title: 'Gallery ',
        description: 'Gallery '
    }

}
export default seoContents
